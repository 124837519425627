import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default () => {
  let mm = gsap.matchMedia();

  //--------------------------------//
  // Menu slide animation
  //--------------------------------//
  // Menu slide animation
  const navigationSlide = document.querySelector("#navigation-slide");
  const q = gsap.utils.selector(navigationSlide);
  const menuSlideAnimation = gsap.timeline({
    paused: true,
    reversed: true,
    defaults: { duration: 0.2, ease: "power4.in" },
  });
  gsap.set(navigationSlide, { xPercent: 100 });
  // Animation
  menuSlideAnimation.set(navigationSlide, { autoAlpha: 1 });
  menuSlideAnimation.to(navigationSlide, { xPercent: 0, duration: 0.2 });
  menuSlideAnimation.from(q("ul li"), {
    x: 100,
    opacity: 0,
    stagger: { amount: 0.3 },
  });
  menuSlideAnimation.from(q(".extra > *"), {
    opacity: 0,
    stagger: { amount: 0.3 },
  });
  // Menu toggle setup
  let tlMenuToggleAll = []; // Collect all menu buttons;
  const menuToggle = document.querySelectorAll(".menu-toggle");
  // Animation function
  function menuToggleAnimation(target) {
    const q = gsap.utils.selector(target);
    // Menu toggle animation
    const tl = gsap.timeline({
      paused: true,
      reversed: true,
      defaults: { duration: 0.3, ease: "power3.in" },
    });

    // ---- Rotate animation ----
    tl.to(q(".anim"), { y: 0 });
    tl.to(q(".anim"), {
      duration: 0.45,
      rotate: gsap.utils.wrap([45 * 3, 45 * 5]),
    });
    tl.to(q(".text"), { duration: 0.1, opacity: 0 }, "<");
    tlMenuToggleAll.push(tl); // Capture all timelines
    return tl;
  }
  // 👆 Click logic function
  function menuToggleClick(target) {
    // Setup aria roles
    target.setAttribute("aria-haspopup", "true");
    target.setAttribute("aria-expanded", "false");
    target.setAttribute("aria-controls", "navigation-slide");

    // Click logic
    target.addEventListener("click", (e) => {
      e.preventDefault();
      document.body.classList.toggle("showNavigationSlide");
      menuSlideAnimation.reversed()
        ? menuSlideAnimation.play()
        : menuSlideAnimation.reverse();
      tlMenuToggleAll.forEach((timeline, index) => {
        timeline.reversed() ? timeline.play() : timeline.reverse();
        // ♿️ Accessibility logic
        menuToggle[index].setAttribute(
          "aria-expanded",
          menuToggle[index].getAttribute("aria-expanded") == "false"
            ? "true"
            : "false",
        );
      });
    });
  }
  document.querySelectorAll(".menu-toggle").forEach((item) => {
    const q = gsap.utils.selector(item);
    const offset = item.getBoundingClientRect().height * 0.2;
    // Ready function
    function ready() {
      // Add animation to button
      if (item.tagName === "BUTTON") {
        menuToggleAnimation(item);
      }
      // 👆 Add click logic
      menuToggleClick(item);
    }
    // Page load animation
    const tl = gsap.timeline({
      onComplete: () => ready(),
    });
    // Animate only if item is a button
    if (item.tagName === "BUTTON") {
      tl.to(q(".anim"), {
        y: gsap.utils.wrap([offset, -offset]),
        duration: 0.15,
        ease: "power4.in",
      });
      tl.from(q(".text"), { opacity: 0 });
    }
  });
  // END Menu slide animation --------------//

  //------------------------------------------------------//
  // On browser 📜 scroll full window height
  //------------------------------------------------------//
  ScrollTrigger.create({
    id: "Scrolled",
    trigger: "body",
    start: `${window.innerHeight * 1.5} bottom`,
    toggleClass: "showScrolled",
  });
  // END On browser 📜 scroll full window height  -------------------------------------//

  mm.add("(max-width: 1400px)", () => {
    if (document.querySelector(".toggle-filter")) {
      const filter = document.querySelector(".alloy-filter");
      gsap.set(filter, { xPercent: -100 });

      const fixedToggle = document.querySelector(".toggle-filter.fixed span");
      const tl = gsap.timeline({
        paused: true,
        reversed: true,
        defaults: { duration: 0.2, ease: "power4.in" },
        onComplete: () => {
          const off = fixedToggle.dataset.off;
          fixedToggle.dataset.off = fixedToggle.innerText;
          fixedToggle.innerText = off;
        },
        onReverseComplete: () => {
          const off = fixedToggle.dataset.off;
          fixedToggle.dataset.off = fixedToggle.innerText;
          fixedToggle.innerText = off;
        },
      });

      tl.set(filter, { autoAlpha: 1 });
      tl.to(filter, { xPercent: 0, duration: 0.2 });

      document.querySelectorAll(".toggle-filter").forEach((item) => {
        item.addEventListener("click", () => {
          document.body.classList.toggle("showAlloyFilter");
          tl.reversed() ? tl.play() : tl.reverse();
          item.setAttribute(
            "aria-expanded",
            item.getAttribute("aria-expanded") == "false" ? "true" : "false",
          );
        });
      });
    }
  });
  //--------------------------------//
  // Alloy Woo cart popup
  //--------------------------------//
  if (document.querySelector(".cartNotif")) {
    const elm = document.querySelector(".cartNotif");
    const closeButton = elm.querySelector("#close-button");
    closeButton.addEventListener("click", function () {
      elm.style.display = "none";
    });
  }
  // END Alloy Woo cart popup --------------//

  //--------------------------------//
  // 📌 Pin container
  //--------------------------------//
  mm.add("(min-width: 992px)", () => {
    document.querySelectorAll(".alloy-scroll").forEach(function (container) {
      // const item = container.querySelector(".alloy-scroll");
      const height = container.querySelector(".size").offsetHeight;

      const timeline = gsap.timeline({
        scrollTrigger: {
          id: "pinScroller",
          trigger: container, // What element triggers the scroll
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "top top", // Can be top, center, bottom
          end: `bottom ${height}px`, // Can be top, center, bottom
          pin: container,
        },
      });
    });
  });
  // END 📌 Pin container --------------//
};
