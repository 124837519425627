import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
// gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers:
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? true
        : false,
  });

  // END Setup 🧦 GSAP -------------------------------------//
  //--------------------------------//
  // Fancy header
  //--------------------------------//
  document
    .querySelectorAll(".alloy-header-type-fancy")
    .forEach(function (container) {
      // ScrollTrigger.create({
      //   trigger: 'body', // What element triggers the scroll
      //   endTrigger: container,
      //   scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      //   start: "clamp(top top)", // Can be top, center, bottom
      //   end: "center top", // Can be top, center, bottom
      //   pin: '.alloy-header-pattern',
      //   pinSpacing: false
      // })
      const q = gsap.utils.selector(container);
      const timeline = gsap.timeline({
        defaults: {
          ease: "none",
        },
        scrollTrigger: {
          trigger: "body", // What element triggers the scroll
          endTrigger: container,
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "clamp(top top)", // Can be top, center, bottom
          end: "center top", // Can be top, center, bottom
          // pin: [q(".pin"), q(".gradient"), ".alloy-header-pattern "],
          pin: q(".pin"),
          snap: {
            snapTo: 1,
            duration: { min: 0.3, max: 0.6 },
          },
          pinSpacing: false,
        },
      });

      const wrap = gsap.utils.wrap([60, 45, 65, 50]);

      timeline.to(
        q(".grid li"),
        {
          duration: 1,
          yPercent: (index) => -wrap(index),
        },
        "<",
      );
      timeline.from(
        q(".gradient"),
        {
          yPercent: -50,
          scaleY: 0.5,
          transformOrigin: "top center",
        },
        "<",
      );
      timeline.from(q("h1 .bar"), {
        duration: 1,
        scaleX: 0,
        transformOrigin: "left center",
        stagger: 0.1,
      });
    });
  // END Fancy header --------------//

  // //--------------------------------//
  // // 🙋 Specialist
  // //--------------------------------//
  // document.querySelectorAll(".profile-fancy").forEach(function (container) {
  //   const q = gsap.utils.selector(container);

  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: container,
  //       start: "top bottom-=10%",
  //       toggleActions: "play none none none"
  //     }
  //   });

  //   // tl.from(q(".circle"), {
  //   //   scale: 0,
  //   //   duration: 0.7,
  //   //   ease: "elastic.out(1, 0.7)",
  //   // })
  //   tl.from(q("img"), {
  //     duration: 0.4,
  //     yPercent: 30,
  //   })
  // });

  // // END 🙋 Specialist --------------//

  //------------------------------------------------------//
  // FAQ toggle
  //------------------------------------------------------//
  document.querySelectorAll(".form-toggle-container").forEach((container) => {
    const items = container.querySelectorAll(".alloy-item");
    items.forEach((item) => {
      // The animation
      const tl = gsap.timeline({
        defaults: { duration: 0.3 },
        reversed: true,
        paused: true,
      });
      tl.set(item.querySelector(".hide"), { autoAlpha: 1 });

      tl.to(item.querySelector(".hide"), { height: "auto" });
      tl.to(item.querySelector(".icon"), { rotate: 180 }, "<");

      // The event listener
      item.addEventListener("click", (e) => {
        tl.reversed() ? tl.play() : tl.reverse();
      });
    });
  });
  // END FAQ toggle -------------------------------------//

  //------------------------------------------------------//
  // FAQ toggle
  //------------------------------------------------------//
  document.querySelectorAll(".cartNotif").forEach((container) => {
    // The animation
    const tl = gsap.timeline();
    tl.from(container, { xPercent: 100, delay: 1 });
  });
  // END FAQ toggle -------------------------------------//
};
